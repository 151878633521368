<template>
  <div class="py-8 container">
    <!-- 尚未有資料 -->
    <div class="text-center hidden">
      <h2 class="text-5xl">還沒有筆記喔</h2>
      <p class="mt-4 text-2xl">建立你的一本筆記或從題目練習開始</p>
      <div class="flex mt-6 justify-center">
        <el-button
          type="primary"
          @click="$router.push({ path: '/exam/past/list' })"
          >前往QB題庫</el-button
        >
      </div>
      <img src="@/assets/imgs/role4.png" alt="" class="mt-12 mx-auto" />
    </div>

    <!-- 已有資料 -->
    <div>
      <div class="box flex items-center">
        <Titles :text="'試題列表'" />
        <Count :total="questions.rowsData.total"></Count>
        <Exclude
          v-model="form.exItem"
          :fields="['today']"
          class="ml-auto mr-6"
        />
        <Btn-Filter
          @toggle="dialog.toggle({ show: true })"
          @reset="reset"
        ></Btn-Filter>

        <BtnStart
          :total="questions.rowsData.total"
          :disabled="!rows.length"
          :folder-id="$route.query.folderId"
          :filter-id="$route.query.filterId"
          :type="$route.query.type || $route.params.slug"
          :order="$route.query.order"
          :order-item="$route.query.orderItem"
          :need-pick-order="true"
          :need-confirm="true"
        />
      </div>
      <div class="my-6">
        <List
          :columns="['name', 'ansStatus', 'conf', 'testerDate']"
          :empty-text="'目前沒有註解過的試題'"
        />
      </div>
    </div>
    <El-Dialog v-model="dialog.state.show" title="進階搜尋">
      <Filter @update="onFilter" />
    </El-Dialog>
  </div>
</template>

<script>
import { ref, provide } from 'vue';

// @component
// import DataType from '@/components/filter/DataTypeVal.vue';
import Titles from '@/components/Titles.vue';
import BtnStart from '@/modules/tester/BtnStart';
import Exclude from '@/components/filter/Exclude.vue';
//@modules question
import List from '@/modules/question/List.vue';
import useQuestions from '@/modules/question/useQuestions';
import BtnFilter from '@/components/BtnFilter.vue';
import { ElDialog, ElButton } from 'element-plus';
import Count from './_Count.vue';
import useDialog from '@/hook/useDialog';
import Question from '@/api/Question.js';

// @modules Filter
import Filter from '@/modules/filter/Memo.vue';
import { Operation } from '@element-plus/icons-vue';
import usePgReset from '@/hook/usePgReset.js';
export default {
  components: {
    List,
    Filter,
    Exclude,
    ElDialog,
    ElButton,
    Titles,
    BtnStart,
    BtnFilter,
    Count,
  },
  beforeRouteEnter(to, from, next) {
    usePgReset(to, from, next);
  },
  setup() {
    const questions = useQuestions({
      method: ref(Question.result),
      defaultForm: {
        type: 'memo',
        orderItem: 'date',
        order: 0,
        filterId: null,
      },
    });
    questions.fetcher();
    provide('questions', questions);

    const dialog = useDialog();

    const onFilter = (e) => {
      questions.updateForm({ filterId: e.filterId, pg: 1 });
      dialog.toggle();
    };

    return {
      form: questions.form,
      rows: questions.rowsData.rows,
      onFilter,
      reset: questions.reset,
      questions,

      dialog,
      Operation,
    };
  },
};
</script>
