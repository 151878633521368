<template>
  <div class="filter filter-theme-info">
    <div class="flex flex-col space-y-3">
      <Subject-Type
        v-model="form.sbtKey"
        :class="isFixSb ? 'pointer-events-none opacity-60' : ''"
      />

      <Subject
        v-model="form.sbjKey"
        :sbt-key="form.sbtKey?.id"
        :class="isFixSb ? 'pointer-events-none opacity-60' : ''"
      />

      <SubjectScope
        v-if="form.sbjKey"
        v-model:part="form.part"
        v-model:chapter="form.chapter"
        v-model:section="form.section"
        :sbt-key="form.sbjKey?.id"
      />
      <QCnt v-model="form.qCnt" :min="1" />
      <IdName
        v-model:name="form.keyword"
        v-model:id="form.key"
        :id-label="'試題ID'"
        :name-label="'關鍵字'"
      />
    </div>
    <div class="flex space-x-6 py-4 justify-center">
      <el-button size="large" type="text" @click="reset">重新填寫 </el-button>
      <el-button type="danger" size="large" @click="add_"
        >加入篩選條件
      </el-button>
    </div>

    <Results :rows="results" @remove="remove" @clear="clear" />

    <div class="py-6 flex justify-center">
      <El-Button
        :disabled="!results.length"
        size="large"
        type="primary"
        round
        class="text-xl min-w-[200px]"
        @click="submit"
        >顯示試題</El-Button
      >
    </div>
  </div>
</template>

<script>
import SubjectType from './form/subjectType/Index.vue';
import Subject from './form/subject/Radio.vue';
import SubjectScope from './form/subjectScope/Index.vue';
import QCnt from './form/qCnt/Single.vue';
import Results from './_Results.vue';
import IdName from './form/IdName.vue';
import useFilter from './useFilter';

import { ElButton } from 'element-plus';

import ApiQuestion from '@/api/Question';
import { ElMessage } from 'element-plus';
export default {
  components: {
    SubjectType,
    Subject,
    SubjectScope,
    Results,
    ElButton,
    QCnt,
    IdName,
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { form, add, remove, reset, results, getFilterId, clear, isFixSb } =
      useFilter({
        excludeResetKeys: ['sbjKey', 'sbtKey'],
        defaultForm: {
          keyword: null,
          key: null,
          sbtKey: {},
          sbjKey: null,
          part: [],
          chapter: [],
          section: [],
          qCnt: { id: '50', name: '50' },
        },
      });

    form.type = 'memo';

    const add_ = () => {
      let valid = true;

      if (!form.sbtKey || !form.sbjKey) {
        valid = false;
      }
      if (!valid) {
        ElMessage({
          message: '請先選擇科目種類/考試科目"',
          center: true,
        });
      } else {
        add();
      }
    };

    const submit = () => {
      getFilterId({ API: ApiQuestion.filterId }).then((res) => {
        if (res.code === 1) {
          emit('update', { filterId: res.data.filterId });
        } else {
          ElMessage({
            message: '無法取得filter id',
            center: true,
          });
        }
      });
    };
    return {
      form,
      add_,
      remove,
      reset,
      results,
      clear,
      submit,
      isFixSb,
    };
  },
};
</script>
